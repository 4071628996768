import React from "react";
import { Provider } from "../interfaces";
interface Context {
  scrollY: number;
  setScrollY: React.Dispatch<React.SetStateAction<number>>;
}

export const ScrollContext = React.createContext({} as Context);

export const ScrollProvider: React.FC<Provider> = ({ children }) => {
  const [scrollY, setScrollY] = React.useState(0);

  return (
    <ScrollContext.Provider
      value={{
        scrollY,
        setScrollY,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
