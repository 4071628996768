import React from "react";
import useGrid from "../hooks/useGrid";
import { Context, Provider } from "../interfaces";

export const ProjectsContext = React.createContext({} as Context);

export const ProjectsProvider: React.FC<Provider> = ({ children }) => {
  const [num, text, toggle, setTotal] = useGrid();

  return (
    <ProjectsContext.Provider
      value={{
        num,
        text,
        toggle,
        setTotal,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
