import React from "react";
import { FormProvider } from "./src/contexts/FormContext";
import { ProjectsProvider } from "./src/contexts/ProjectsContext";
import { RadioProvider } from "./src/contexts/RadioContext";
import { ScrollProvider } from "./src/contexts/ScrollContext";

export const wrapRootElement = ({ element }) => (
  <ScrollProvider>
    <ProjectsProvider>
      <RadioProvider>
        <FormProvider>{element}</FormProvider>
      </RadioProvider>
    </ProjectsProvider>
  </ScrollProvider>
);
