import React from "react";

const useGrid = (): [
  number,
  string | null,
  () => void,
  (total: number) => void
] => {
  const didMountRef = React.useRef<boolean>(false);
  const initNum = React.useRef<number>(8);
  const total = React.useRef<number>(0);
  const [num, setNum] = React.useState(8);
  const [text, setText] = React.useState<string | null>(null);

  React.useEffect(() => {
    const w = window.innerWidth;
    if (w < 768) {
      setNum(2);
      initNum.current = 2;
    }
    if (w >= 768 && w < 1025) {
      setNum(6);
      initNum.current = 6;
    }

    setText("Show More");
  }, []);

  React.useEffect(() => {
    if (didMountRef.current) {
      if (num === initNum.current) {
        setText("Show More");
      }
      if (num >= total.current) {
        setText("Show Less");
      }
    }
    didMountRef.current = true;
  }, [num]);

  const setTotal = (n: number) => (total.current = n);

  const toggle = () => {
    if (text === "Show Less") {
      if (initNum.current === 6) {
        // if grid of 6 then show 2 less cards
        setNum(num - initNum.current / 3);
      } else {
        // if grid of 2, or 8 then show half of the init num of less cards
        setNum(num - initNum.current / 2);
      }
    } else {
      if (initNum.current === 6) {
        // if grid of 6 then show 2 more cards
        setNum(num + initNum.current / 3);
      } else {
        // if grid of 2, or 8 then show half of the init num of cards
        setNum(num + initNum.current / 2);
      }
    }
  };

  return [num, text, toggle, setTotal];
};

export default useGrid;
