import React from "react";
import useGrid from "../hooks/useGrid";
import { Context, Provider } from "../interfaces";

export const RadioContext = React.createContext({} as Context);

export const RadioProvider: React.FC<Provider> = ({ children }) => {
  const [num, text, toggle, setTotal] = useGrid();

  return (
    <RadioContext.Provider
      value={{
        num,
        text,
        toggle,
        setTotal,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};
