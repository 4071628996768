import React from "react";
import { Provider } from "../interfaces";
interface Context {
  open: boolean;
  isOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormContext = React.createContext({} as Context);

export const FormProvider: React.FC<Provider> = ({ children }) => {
  const [open, isOpen] = React.useState(false);

  return (
    <FormContext.Provider
      value={{
        open,
        isOpen,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
